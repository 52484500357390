import React from "react"
import ContactForm from "../components/contact_form"
import Layout from "../components/layout"
import WomanWithPencil from "../images/svg/woman_with_pencil.svg"

export default function NotFound() {
  return (
    <Layout showButtons={false}>
      <div className="flex flex-row px-4 pt-20 mx-auto md:space-x-12 md:max-w-screen-md lg:max-w-screen-lg">
        <WomanWithPencil className="hidden md:block" alt=""/>
        <div className="w-full md:w-2/3" >
        <ContactForm />
        </div>
      </div>
    </Layout>
  )
}
