import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Link } from "gatsby"
import ProgressButton from "./button"
import AppContext from "../context/app_context"

export default function ContactForm(props) {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (fields, state, resetForm) => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha("homepage")
    const email = fields.email
    const message = fields.message

    const data = JSON.stringify({ email, message, token })
    const axiosOptions = {
      url: `${process.env.MESSAGE_URL}`,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }
    setLoading(true)
    axios(axiosOptions)
      .then(response => {
        resetForm({ values: "" });
        setLoading(false);
        state.openContactSuccessDialog();
      })
      .catch(err => {
        setLoading(false)
      })
  }

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation()

  return (
    <AppContext.Consumer>
      {state => (
        <Formik
          initialValues={{
            acceptTerms: false,
            email: "",
            message: "",
          }}
          validationSchema={Yup.object().shape({
            acceptTerms: Yup.bool().oneOf([true]),
            email: Yup.string().email().required(),
            message: Yup.string().required(),
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values, state, resetForm)
          }}
        >
          {({ errors, touched }) => (
            <div className="flex flex-col">
              <Form>
                <div className="flex flex-col w-full">
                  <div
                    className={`inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                  >
                    <div className="flex flex-col py-6 bg-white">
                      <h2 className="text-2xl">
                        {t("contactTitleFirstPart")}
                        <span className="font-bold">
                          {t("contactTitleSecondPart")}
                        </span>
                      </h2>
                      <h3 className="pt-4 pb-6 text-neutral20">
                        {t("contactSubtitle")}
                      </h3>
                      <label
                        htmlFor="email"
                        className="block pb-1 text-sm font-medium text-neutral20"
                      >
                        {t("contactEmailLabel")}
                      </label>

                      <Field
                        type="email"
                        name="email"
                        placeholder={t("contactEmailHint")}
                        className={`${
                          errors.email && touched.email
                            ? "border-red-400"
                            : "border-neutral60"
                        } px-6 py-4 rounded-xl ring-0 focus:ring-0 text-primary40`}
                      />
                      <label
                        htmlFor="message"
                        className="block pt-6 pb-1 text-sm font-medium text-neutral20"
                      >
                        {t("contactMessageLabel")}
                      </label>
                      <Field
                        type="text"
                        name="message"
                        component="textarea"
                        placeholder={t("contactMessageHint")}
                        className={`${
                          errors.message && touched.message
                            ? "border-red-400"
                            : "border-neutral60"
                        } px-6 py-4 max-h-64 rounded-xl ring-0 focus:ring-0 text-primary40`}
                      />

                      <div className="pt-4 pl-4">
                        <label className="flex items-center">
                          <Field
                            type="checkbox"
                            name="acceptTerms"
                            className={`${
                              errors.acceptTerms && touched.acceptTerms
                                ? "border-red-400"
                                : "border-neutral60"
                            } rounded ring-0 focus:ring-0 text-primary40`}
                          />
                          <span
                            className={`${
                              errors.acceptTerms && touched.acceptTerms
                                ? "text-red-400"
                                : "text-neutral20"
                            } ml-2 text-xs `}
                          >
                            {t("contactTermsFirstPart")}
                            <Link
                              className={`underline hover:text-primary40`}
                              to="/privacy"
                            >
                              {t("contactTermsSecondPart")}
                            </Link>
                          </span>
                        </label>
                      </div>

                      <div className="mt-8">
                        <ProgressButton loading={loading}>
                          <span className="block px-6 py-3 uppercase">
                            {t("buttonSendMessage")}
                          </span>
                        </ProgressButton>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </AppContext.Consumer>
  )
}
